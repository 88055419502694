:root {
  --color-primary: #CF9E29;
  --color-secondary: #0D6EFD;
  --color-grey-lighter: #DDD;
  --color-grey-light: #BBB;
  --color-grey: #999;
  --color-grey-dark: #666;
  --color-grey-darker: #333;
  --color-grey-darkest: #111;

  --header-height: 60px;
}
body {
  cursor: default;
  overflow-x: hidden;
}
#privacy-policy {
  height: fit-content;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: white #111;
  scrollbar-width: thin;
}

/* #region FONT IMPORTS */
@import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Faculty+Glyphic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mate:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap');
/* #endregion */

/* #region HEADER */
.header {
  background-color: black;
  height: var(--header-height);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}
.header-logo {
  height: var(--header-height);
  padding: 0 14px;
}
.menu {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 1000px;
}
.menu a {
  background-color: black;
  color: white;
  display: inline-block;
  font-size: 13px;
  line-height: calc(var(--header-height) - 28px);
  padding: 14px;
  text-align: center;
  text-decoration: none;
}
.menu a.login {
  background-color: var(--color-primary);
}
.menu a.icon {
  display: none;
}
.menu-ex {
  display: inline-block;
}
.menu-ex .menu-ex-item {
  background-color: black;
  border: none;
  color: white;
  font-family: inherit;
  font-size: 13px;
  font-weight: inherit;
  line-height: calc(var(--header-height) - 28px);
  margin: 0;
  outline: none;
  padding: 14px;
}
.menu-ex-content {
  display: none;
  min-width: 160px;
  position: absolute;
}
.menu-ex-content a {
  background-color: white;
  color: black;
  display: block;
  font-weight: 500;
  line-height: 32px;
  padding: 14px;
  text-align: left;
  text-decoration: none;
}
.menu-ex:hover .menu-ex-content {
  display: block;
}
.menu a:hover, .menu-ex:hover .menu-ex-item {
  background-color: var(--color-primary);
  color: white;
}
.menu-ex-content a:hover {
  background-color: var(--color-primary);
  color: white;
}
.header-offset {
  margin-top: var(--header-height);
}
@media screen and (max-width: 1100px) {
  .menu a, .menu-ex .menu-ex-item {
    display: none;
  }
  .menu a.icon {
    display: block;
    position: absolute;
    right: 0;
    top:0;
  }
  .menu.responsive {
    position: relative;
    width: 100%;
  }
  .menu.responsive a.icon {
    position: fixed;
    right: 0;
    top: 0;
  }
  .menu.responsive a {
    display: block;
    text-align: left;
  }
  .menu.responsive .menu-ex {
    display: block;
    text-align: left;
  }
  .menu.responsive .menu-ex-content {
    position: relative;
  }
  .menu.responsive .menu-ex .menu-ex-item {
    display: block;
    text-align: left;
    width: 100%;
  }
}
/* #endregion */

/* #region FOOTER */
.footer {
  background: var(--color-grey-darkest);
  color: var(--color-grey-dark);
  padding: 20px 0 120px;
}
.footer-logo {
  height: 80px;
}
.desc-text {
  display: block;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 14px;
  font-style: oblique;
  padding: 14px 0;
  width: 80%;
}
.subs-form {
  width: 80%;
}
.subs {
  background: #dfedf7;
}
.quick-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.quick-links ul li a {
  color: #555;
  font-optical-sizing: auto;
  font-size: 15px;
  font-style: normal;
  text-decoration: none;
}
.quick-links ul li a:hover {
  color: var(--color-grey-dark);
}
.social-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.social-links ul li {
  float: left;
}
.social-links ul li a {
  padding: 5px 3px;
}
.social-links li a img {
  height: 30px;
}
.copy-right {
  background: var(--color-grey-darkest);
  height: 60px;
}
.copy-text {
  color: #444;
  font-size: 14px;
  padding-top: 15px;
  text-align: center;
}
.copy-text a {
  text-decoration: none;
}
@media screen and (max-width: 1200px) {
  .subscribe {
    width: 70%!important;
  }
  .quick-links {
    width: 30%!important;
  }
  .social-links {
    text-align: center;
    width: 100%!important;
  }
  .social-links ul li {
    display: inline-block;
    float: none;
  }
}
@media screen and (max-width: 800px) {
  .subscribe {
    text-align: center;
    width: 100%!important;
  }
  .desc-text {
    margin: 0 auto;
  }
  .subs-form {
    margin: 0 auto;
  }
  .quick-links {
    text-align: center;
    width: 100%!important;
  }
}
/* #endregion */

/* #region MAIN */
.video-container {
  height: calc(100vh - var(--header-height));
  margin-top: var(--header-height);
  overflow: hidden;
  position: relative;
  width: 100%;
}
.video-background {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.title-logo {
  position: absolute;
  text-align: center;
  top: calc(50vh - var(--header-height) - 200px);
  width: 100vw;
}
.title-logo img {
  height: 30vh;
  max-height: 250px;
}
.title-label {
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 5vh;
  font-style: normal;
  font-weight: 700;
  margin-top: 25px;
}
.title-label-sm {
  color: white;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 3vh;
  font-style: normal;
  font-weight: 700;
  margin-top: 25px;
}
.title-label span, .title-label-sm span {
  color: var(--color-primary);
  font-weight: 900;
}
.title-label a, .title-label-sm a {
  color: white;
  text-decoration: none;
}
.heading {
  color: white;
  font-family: "Outfit", sans-serif;
  font-size: 3.5vh;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 10vh 5vw 5vh;
  text-align: center;
}
.heading-signature {
  color: var(--color-primary);
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 4.5vh;
  font-style: normal;
  font-weight: 900;
  margin: 0;
  padding: 5vh 5vw 10vh;
  text-align: right;
}
.fullscreen {
  height: 300vh;
  position: sticky;
  overflow: hidden;
  top: 0;
}
.fade-in-from-left {
  color: var(--color-grey-dark);
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 16vh;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  top: 15vh;
  transform: translateX(-40vw);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}
.fade-in-from-right {
  background: linear-gradient(270deg, var(--color-grey-darkest) 10%, var(--color-grey-darker) 90%);
  border-radius: 100px;
  border-top-left-radius: 0;
  color: var(--color-grey-lighter);
  font-family: "Mulish", sans-serif;
  font-size: 2.5vh;
  font-style: normal;
  height: 45vh;
  margin: 0 5vw;
  opacity: 0;
  overflow: hidden;
  padding: 5vh 5vw;
  position: relative;
  top: 20vh;
  transform: translateX(40vw);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}
.fade-in-from-right span {
  color: var(--color-primary);
  display: block;
  font-size: 2vh;
  font-weight: 900;
  margin: 0 auto;
  text-align: center;
  width: 50vw;
}
.cex-bg {
  background: #111;
  background: linear-gradient(90deg, #333 0%, #111 100%);
  position: relative;
}
.cex-images img {
  height: 69px;
  margin-bottom: 20px;
  width: 100%;
}
.home-paragraph {
  background-color: var(--color-grey-darkest);
  color: var(--color-grey-lighter);
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-size: 3vh;
  font-style: normal;
  padding: 5vh 5vw 10vh !important;
  position: relative;
  text-align: justify;
}
@media screen and (max-width: 1000px) {
  .fade-in-from-right span {
    font-size: 1.5vh;
  }
  .cex-link {
    text-align: center;
    width: 100%!important;
  }
  .cex-images {
    text-align: center;
    width: 100%!important;
  }
  .cex-images img {
    width: 80%!important;
  }
}
@media screen and (max-width: 800px) {
  .title-logo img {
    height: auto;
    width: 80vw;
  }
  .title-label {
    font-size: 4.5vw;
  }
  .title-label-sm {
    font-size: 3.5vw;
  }
  .heading {
    font-size: 3.5vw;
  }
  .heading-signature {
    font-size: 3.5vw;
  }
}
@media screen and (max-width: 700px) {
  .cex-images div {
    width: 100%!important;
  }
  .cex-images img {
    width: 60%!important;
  }
}
/* #endregion */

/* #region FONT COLORS */
.font-color-grey-ligher {
  color: var(--color-grey-lighter);
}
.font-color-grey {
  color: var(--color-grey);
}
.font-color-grey-dark {
  color: var(--color-grey-dark);
}
.font-color-primary {
  color: var(--color-primary);
}
.font-color-white {
  color: white;
}
/* #endregion */

/* #region BACKGROUND COLORS */
.bg-color-black {
  background-color: black;
}
.bg-color-grey-dark {
  background-color: var(--color-grey-dark);
}
.bg-color-grey-darker {
  background-color: var(--color-grey-darker);
}
.bg-color-grey-darkest {
  background-color: var(--color-grey-darkest);
}
.bg-color-white {
  background-color: white;
}
/* #endregion */

/* #region ANIMATIONS */
@keyframes fade-in-from-left {
  0% {
    filter: blur(50px);
    right: 100vw;
    opacity: 0;
  }
  40% {
    filter: blur(0);
    right: 0;
    opacity: 1;
  }
  50% {
    filter: blur(0);
    right: 0;
    opacity: 1;
  }
  80% {
    filter: blur(50px);
    right: 40vw;
    opacity: 0;
  }
}
@keyframes fade-in-from-right {
  0% {
    filter: blur(50px);
    left: 100vw;
    opacity: 0;
  }
  40% {
    filter: blur(0);
    left: 0;
    opacity: 1;
  }
  50% {
    filter: blur(0);
    left: 0;
    opacity: 1;
  }
  80% {
    filter: blur(50px);
    left: 40vw;
    opacity: 0;
  }
}
/* #endregion */

.img-box {
  border: 0;
  margin: 0;
  padding: 24px 12px;
}
.img-box img {
  width: 100%;
}
.col-header {
  background: radial-gradient(var(--color-grey-darkest), transparent, transparent);
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 900;
  text-align: center;
  width: 100%;
}


.horizontal-rule {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(153, 153, 153, 0.75), rgba(0, 0, 0, 0));
  margin-bottom: 4vh;
}
.title {
  font-family: "Inter", sans-serif;
  font-weight: 900;
}
.tokenomics th, .tokenomics td {
  background-color: var(--color-grey-darkest)!important;
}
.tokenomics td {
  color: var(--color-primary)!important;
  font-weight: 700;
}
.tokenomics th {
  color: white!important;
  font-family: "Inter", sans-serif;
  font-weight: 900;
  padding: 2vh 0!important;
}
.content {
  position: absolute;
  top: 10%;
  padding: 5%;
  /* 
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center; */
}
.content p {
  color: white;
  font-size: 17px;
}
.gold a{
  text-decoration: none;
  color: white;
}
@media (min-width:480px)  {
  body, html{
    /* width: 107%; */
  }
}
@media only screen and (min-width: 768px) {
 body, html{
    /* width: 107%; */
  }
}
@media only screen and (min-width: 600px) {
  body, html{
    /* width: 107%; */
  }
}

.text1 {
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 19px;
}

.col-tex p {
  text-align: justify;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 3vh;
  padding: 5vh 2vw;
  color: var(--color-grey-lighter);
}

.form-home input, .form-home select {
    background: #e8eef2;
    border-radius: 15px;
}

.h2-color {
    color: #606060;
}

.bold {
    font-weight: bold;
}

.bold {
    font-weight: bold !important;
}

.background-primary {
    background-color: var(--color-primary)!important;
}

.gold-price {
    font-size: 20px;
    font-family: "Faculty Glyphic", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #ff5c00;
    text-shadow: 1px 1px 1px #6e6b6b;
    margin: 5px;
}

.bbg1 {
    background: #1c1c1c;
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
}

.gold {
    color: white !important;
    text-shadow: black;
    text-shadow: 0px 3px 3px black;
}

.gold {
    animation: blinker 1s linear infinite;
    font-size: 16px;
    border: 1px solid #89ff00;
    padding: 4px 9px;
    background: #cf9e29;
    border-radius: 5px;
}

@keyframes blinker {
    50% {
        opacity: 0.6;
    }
}

.icon {
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .galary-inner{
    flex-direction: column !important;
  }
  .tab-100{
    width: 100% !important
  }
  .date-input div{
    width: 100% !important;
    margin: 2px !important;
  }
  .secnd {
      display: none;
  }
  .social-logo li a img {
      height: 23px;
  }
  .icon {
      display: block;
      position: absolute;
      right: 18px;
  }
  .hidden {
      display: none;
  }
  .width-80{
    width: 100% !important;
  }
  .sub-menu{
    width: 100% !important;
  }

  .show {
      display: block;
      width: 100%;
      background: white;
      z-index: 9999;
  }

  .menu-nav ul li {
      display: block;
      padding: 5px;
      border: 1px solid #ffffff;
      background: #d0cccc;
      width:100%;
  }
  .bg-dark1 .col-sm-6, .main-img .col-sm-6{
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .galary-inner{
    flex-direction: column !important;
  }
  .tab-100{
    width: 100% !important
  }
  .date-input div{
    width: 100% !important;
    margin: 2px !important;
  }
    .secnd {
        display: none;
    }
    .social-logo li a img {
        height: 23px;
    }
    /* .icon {
        display: block;
        position: absolute;
        right: 18px;
    } */
    .mob-width-100{
      width: 100% !important;
    }
    .hidden {
        display: none;
    }
    .width-80{
      width: 100% !important;
    }
    .sub-menu{
      width: 100% !important;
    }

    .show {
        display: block;
        width: 100%;
        background: white;
        z-index: 9999;
    }

    /* .menu-nav ul li {
        display: block;
        padding: 5px;
        border: 1px solid #ffffff;
        background: #d0cccc;
    } */
    .bg-dark1 .col-sm-6, .main-img .col-sm-6{
      text-align: center;
    }
    
}

.s-menu {
    height: 40px;
    text-align: right;
    background: #e2e2e2;
    padding: 5px;
}

.pol-div {
    display: flex;
    justify-content: flex-start;
}

.pol-div div.b {
    margin: 0px 10px;
    width: 25%;
}

.bg-aa {
    background: #dddddd;
}

.bg-k {
    /* background: #e7e5e5; */
    background: url('./img/gold-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.bgform{
  background: #3938385e;
}
.bgform label, .bgform h3{
  color: white;
}


.button-div button svg {
    margin: 5px;
    size: 30px;
}

.button-div button {
    margin: 5px;
    background: #607d8b;
}


.bbbg {
    background: rgb(219,218,221);
background: linear-gradient(90deg, rgba(219,218,221,1) 0%, rgba(201,197,205,1) 100%);
}

/*************************/

.regBox{
    height: 700px;
    overflow-y: scroll;
  }
  .bg-c{
    background: url('./img/bg.png');
    /* height: 700px; */
    background-size: cover;
  }
  .black-bg{
    /* height: 700px; */
    background: #000000b8;
    padding-top: 100px;
  }
  .main-img img{
    width: 300px;
    height: 93px;
    margin-bottom: 20px;
  }
  
  .bg-2{
    background: url('./img/gold.png');
    height: 600px;
    background-size: cover;
  }
  .padding-top{
    padding-top: 100px;
  }
  .live-track-img img{
    width: 234px;
    margin: 28px 0px;
  }
  .bg-dark1{
    background: #00000066;
    height: 600px;
  }

  /*************************************/
  .login-bg{
    background: url('./img/Banner3.png');
    height: 650px;
    background-size: cover;
    overflow: hidden;
  }
  .log-box{
    height: 300px;
    margin-top: 20%;
    
  }
  .center-div{
    margin: 0 auto;
    width: 50%;
    text-align: center;
  }
  .box .css-r47a1p-MuiFormControl-root{
    width: 100% !important;
  }
  .box p.text-right{
    text-align: right;
    margin-bottom: 0px;
  }
  .box p.signp{
    padding-left: 8px;
      margin-top: 10px;
  }
  .box{
    display: flex;
      flex-direction: column;
  }
  .css-1q964xs-MuiFormLabel-root-MuiInputLabel-root{
    top: -7px !important;
  }
  .custom-sidebar{
    background-image: url('./img/Banner3.png'); /* Adjust the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .custom-sidebar .pro-sidebar-inner {
    background: transparent; /* Ensure the inner content doesn't have its own background */
  }
  .dashboard .logo{
    width: 100%;
  }
  .right{
    width: 100%;
    background: rgb(223, 222, 222);
  }
  .in-hdr .css-kn4dux-MuiFormControl-root-MuiTextField-root{
    width: 400px ;
  }
  .in-hdr{
    background: white;
    display: flex;
      border-bottom: 1px solid #a09f9f;
      padding-top: 6px;
      border-left: 1px solid #a09f9f;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: space-around;
      justify-content: space-between;
      align-items: flex-start;
      box-shadow: 1px 1px 6px 7px #a09c9c;
  }
  .box{
    margin-top: 23px;
  }
  .text-inner{
    margin: 20px;
  }
  .css-lc42l8-MuiInputBase-input-MuiOutlinedInput-input, .css-1jk99ih-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 8.5px 14px !important;
  }
  .css-1xgnz6y-MuiStack-root{
    padding-top: 5px !important;
  }
  .login-img{
    width: 150px;
  }
  /* .otp:focus {   
    border-color: rgba(126, 239, 104, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
    outline: 0 none;
  } */
  .otp{
    width: 2em !important;
  
  }
  .dashboard-ul li{
    border: 1px dotted;
    margin: 2px;
  }
  .dashboard-ul{
    display: flex;
      flex-direction: row;
      align-content: space-around;
      justify-content: space-around;
  }
  .add-row td {
    padding: 10px 0px;
  }
  
  
  
  .regBox{
    height: 700px;
    overflow-y: scroll;
  }
  .bg-c{
    background: url('./img/bg.png');
    /* height: 700px; */
    background-size: cover;
  }
  .black-bg{
    /* height: 700px; */
    background: #000000b8;
    padding-top: 100px;
  }
  /* .main-img img{
    width: 300px;
    height: 93px;
    margin-bottom: 20px;
  } */
  
  .bg-2{
    background: url('./img/gold.png');
    height: 600px;
    background-size: cover;
  }
  .padding-top{
    padding-top: 100px;
  }
  .live-track-img img, .main-img img{
    width: 234px;
    height: 70px;
    margin: 28px 0px;
  }
  .bg-dark1{
    background: #00000066;
    height: 600px;
  }

  .width-100{
    width: 100%;
  }
  .img-a{
    width:40px;
    margin: 0 14px;
  }
  .scroll{
    height: 150px;
    overflow-y: scroll;
  }
  .log-btn{
    background: #a5a5a5;
    border-radius: 16px;
    padding: 9px;
  }
  .message-success{
    text-align: center;
    color: green;
    background: #d3dccf;
  }

  /********************************************/
  .bg-white{
    background: white;
    padding: 10px;
  }
  .input-grp  svg{
    font-size: 21px
  }

  .bg-white .form-label{
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
  }
  .bg-white input, .bg-white select, .bg-white textarea{
    border: 1px solid #a8e5f8;
  }
  .bg-one{
    height: 300px;
    background: url('./img/coin-bg.jpg')
  }
  .new-s h4 {
    color: #2196F3;
}
.new-s p{
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.sub-menu{
  padding: 0px;
  margin: 0px;
  display: none;
  width: 104px;
}
.sub-menu li{
  position: relative;
  position: relative;
  display: block;
  top: 0;
  width: 100%;
  white-space: nowrap;
  z-index: 1;
  background: #fff;
  margin: 0px !important;
}
.sub-menu li a{
  padding: 5px;
  display: block;
}
.event-box{
  background: #4c4c4c;
    color: white;
    padding: 10% 18%;
    font-size: 53px;
}
.width-100{
  width: 100%;
}
.new-s a{ text-decoration: none; }

.button-div{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.width-100{
  width: 100%;
}
.hight-1{
  height: 200px;
}
.client-galary{
  color: white;
  padding: 20px;
  text-align: center;
}
.galary-inner{
  padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.client-galary img{
  width: 124px;
}
.cca img{
  width: 100px;
  
}
.cca{
  background: #898989;
}
.img-1{
  height: 200px;
    margin: 5px;
}
.img-1 img{
  height: 177px;
}
.ck-editor__editable_inline {
  min-height: 400px;
}
.list-data .active{
  background: #9e9e9e;
}
.main-right{
  text-align: right;
    right: 10px;
    position: relative;
    bottom: 5px;
}
.token-img{
  width: 25%;
  float: left;
  margin-right: 29px;
}
.width-80{
  width: 80%;
  height: 80%;
}
.img-cointer{
  position: relative;
  text-align: center;
}

.bg-aa {
  background-color: black; /* Set the background to black */
  color: inherit; /* Ensure text color stays as it is */
}

.date-picker-c{
  height: 10px !important;
}
.date-input input{
  height: 10px !important;
}
.text-justify{
  text-align: justify;
}
.poll-img{
  width: 100%;
  height: 500px;
}
.font-15{
  font-size: 17px;
  padding: 12px;
  background: #9e9e9e;
}
.flex{
  display: flex;
}
.wl-address p{
  margin: 0px;
}
.wl-address{
  border: 1px solid #008eff;
  padding: 5px;
}
.blance-text svg{
  font-size: 30px;
}

/* Chrome, Safari, and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* IE, Edge, and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}